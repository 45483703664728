AOS.init();
$(window).bind('scroll', function() {
    var navHeight = $(window).height() - 71;
    if ($(window).scrollTop() > 0) {
        $('header').addClass('fixed');
    } else {
        $('header').removeClass('fixed');
    }
});

$('.slider').slick({
    arrows: false,
    autoplay: false,
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
});

new WOW().init();

